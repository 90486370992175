import React from 'react';
import HelloWorld from './HelloWorld';
import './App.css';

function App() {
  return (
    <div className="App">
      <HelloWorld />
    </div>
  );
}

export default App;
